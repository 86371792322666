<template>
  <div class="device_preview pb-6 px-12">
    <v-skeleton-loader
      v-if="device"
      :loading="deviceDetailsLoading"
      type="list-item, divider, list-item-avatar, list-item-three-line@4, list-item-avatar-two-line@4"
      class="skeleton-loader--table"
    >
      <div
        class="d-flex align-center justify-space-between pb-4 pt-4 details-preview-header coronet-white-base"
      >
        <span v-if="showIsolationStatus" class="body2 text-red-dark">
          {{ $t(`devices.statuses.${deviceDetails.isolationStatus}`) }}
          {{ getFormattedDateTime(deviceDetails.lastActivity, "ll") }}
        </span>
        <span
          v-else
          class="body2 text-indigo-medium"
          data-testid="devices-preview-protected-since-date"
        >
          {{ $t("general.protectedSince") }}
          {{ getFormattedDateTime(deviceDetails.firstActivity, "ll") }}
        </span>
        <v-menu v-if="actionsList.length" offset-y location="bottom right">
          <template #activator="{ props }">
            <v-btn
              class="ml-8"
              rounded
              color="primary"
              data-testid="devices-preview-actions-btn"
              v-bind="props"
            >
              {{ $t("general.actions") }}
              <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="action in actionsList"
              :key="action"
              :disabled="isActionDisabled(action)"
              @click="onActionClick(action, deviceDetails)"
            >
              <v-list-item-title :data-testid="`devices-page-action-${action}`">
                {{ $t(`devices.actions.${action}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <msp-device-preview-info-section
        :is-mobile="isMobile"
        :action-not-allowed="actionNotAllowed"
        :device-details="deviceDetails"
        @action-clicked="onActionClick"
        @merge-duplicates-clicked="mergeDuplicateDevices(deviceDetails.enrollmentCode)"
        @ignore-duplicates-clicked="ignoreDuplicateDevicesMerge(deviceDetails.enrollmentCode)"
      ></msp-device-preview-info-section>
      <template v-if="!isMobile">
        <drives-list
          class="mb-5"
          :drives="deviceDetails.drives"
          :loading="deviceDrivesLoading"
          :enrollment-code="deviceDetails.enrollmentCode"
          :os-type="deviceDetails.osType"
          :device-details="deviceDetails"
        />
        <entity-tickets-list
          class="mb-5"
          :show-info-tooltip="restrictedTicketModules.length > 0"
          :info-tooltip-text="modulesTooltipText"
          :access-restricted="ticketsAccessRestricted"
          :tickets="deviceDetails.tickets"
          :show-resolve="!deviceDetails.offline"
          :all-open-tickets-link="allOpenTicketsLink"
          @view-vulnerability="viewVulnerability($event)"
        />
        <last-logged-in-users
          class="mb-5"
          :last-logged-in-users="deviceDetails.lastLoggedInUsers"
        />
      </template>
      <entity-activity-logs
        :hide-all-activity-link="true"
        :access-restricted="activityLogsAccessRestricted"
        :activity-logs="deviceDetails.activityLogs"
        :entity-id="deviceDetails.enrollmentCode"
        search-prefix="enrollmentCode"
        :loading="false"
        @undo-clicked="undoActivityLog(deviceDetails.enrollmentCode, $event)"
      />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isAccessRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { ActionTypeTarget, DeviceAction, IsolationStatus, OsType } from "@/constants/devices.ts";

import { computed, defineComponent, type PropType, watch, ref } from "vue";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module.ts";
import type { DeviceLabel } from "@/_store/devices-settings.module.ts";
import { TicketTrigger, TicketStatus } from "@/constants/tickets.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import { i18n } from "@/plugins/i18n.ts";
import { type PrivacySensitiveDataSettings } from "@/_store/endpoint-data-governance/privacy-sensitive-data.module.ts";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import { ModalWidth } from "@/constants/modals.ts";
import DrivesList from "@/components/devices/DrivesList.vue";
import LastLoggedInUsers from "@/components/devices/LastLoggedInUsers.vue";
import DlpScanModal from "@/components/modals/DlpScanModal.vue";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import AddOrRemoveDeviceLabelModal from "@/components/modals/AddOrRemoveDeviceLabelModal.vue";
import MspDevicePreviewInfoSection from "@/components/msp/MspDevicePreviewInfoSection.vue";
import EntityActivityLogs from "@/components/EntityActivityLogs.vue";
import EntityTicketsList from "@/components/EntityTicketsList.vue";
import MspRemoteShellModal from "@/components/MspRemoteShellModal.vue";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions.ts";
import {
  type MspDeviceDetails,
  type MspDeviceListItem,
  useMspDevicesStore,
} from "@/_store/msp/msp-devices.module.ts";
import { RouteName } from "@/constants/routes.ts";
import isEmpty from "lodash/isEmpty";

export default defineComponent({
  components: {
    MspDevicePreviewInfoSection,
    LastLoggedInUsers,
    EntityActivityLogs,
    EntityTicketsList,
    DrivesList,
  },
  props: {
    device: {
      type: Object as PropType<MspDeviceListItem | null>,
      default: null,
    },
  },
  setup(props) {
    const dialogsStore = useDialogsStore();
    const devicesStore = useMspDevicesStore();
    const { deviceDetails, deviceDetailsLoading, deviceDrivesLoading } = storeToRefs(devicesStore);
    const {
      undoActivityLog,
      getDeviceDetails,
      applyDeviceAction,
      downloadLogs,
      getDeviceAdvancedInfo,
      getActions,
      mergeDuplicateDevices,
      ignoreDuplicateDevicesMerge,
      getMonitoringSettingsByWorkspace,
    } = devicesStore;
    const router = useRouter();
    const allOpenTicketsLink = computed(() => {
      return {
        path: "/msp/tickets",
        query: {
          search: deviceDetails.value?.enrollmentCode,
          eventTriggers: deviceDetails.value?.tickets.map((v) => v.name).join(","),
          status: TicketStatus.OPEN,
        },
      };
    });

    const deviceSettings = ref<PrivacySensitiveDataSettings>({
      monitorPII: false,
      monitorPCI: false,
      monitorPHI: false,
      monitorNPI: false,
    });

    const actionsList = ref<DeviceAction[]>([]);

    const showIsolationStatus = computed(() => {
      return (
        deviceDetails.value?.isolationStatus &&
        [IsolationStatus.ISOLATED].includes(deviceDetails.value.isolationStatus)
      );
    });

    const deviceSettingsDisabled = computed(() => {
      return (
        !isEmpty(deviceSettings.value) &&
        !Object.values(deviceSettings.value).every((value) => !!value)
      );
    });

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.VIEWS,
      "devicesView"
    );

    const activityLogsAccessRestricted = isAccessRestricted(
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      WorkspaceManagementScopeSections.ACTIVITY_LOGS
    );

    const isMobile = computed(() => {
      return deviceDetails.value.osType
        ? [OsType.IOS, OsType.ANDROID].includes(deviceDetails.value.osType)
        : false;
    });

    const ticketsAccessRestricted = (
      [
        SubscriptionModule.EDR,
        SubscriptionModule.ENDPOINT_SECURITY,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
      ] as const
    ).every((module) => isAccessRestricted(RolePermissionsScope.TICKETS, module));

    const restrictedTicketModules = (
      [
        SubscriptionModule.EDR,
        SubscriptionModule.ENDPOINT_SECURITY,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
      ] as const
    ).filter((module) => isAccessRestricted(RolePermissionsScope.TICKETS, module));

    const modulesTooltipText = i18n.global.t(
      "entityTicketsList.modulesTooltip",
      {
        modules: restrictedTicketModules
          .map((module) => i18n.global.t(`subscriptions.modules.${module}`))
          .join(", "),
      },
      restrictedTicketModules.length
    );

    async function onActionClick(
      action: DeviceAction,
      details: MspDeviceDetails,
      payload?: DeviceLabel
    ) {
      const item = {
        action,
        enrollmentCode: details.enrollmentCode,
        enrollmentCodes: [details.enrollmentCode],
      };
      switch (action) {
        case DeviceAction.REMOVE_LABEL:
          await applyDeviceAction({
            action,
            item: {
              ...item,
              metadata: {
                labels: [payload?.id],
              },
            },
          });
          break;
        case DeviceAction.EXAMINE_MALWARE:
          await router.push({
            name: RouteName.MSP_TICKETS_PAGE,
            query: {
              eventTriggers: TicketTrigger.MALWARE_ON_ENDPOINT,
              search: details.enrollmentCode,
            },
          });
          break;
        case DeviceAction.VIEW_DETAILS:
          await router.push({
            name: RouteName.MSP_TICKETS_PAGE,
            query: {
              search: details.enrollmentCode,
              eventTriggers: details.vulnerabilities.join(","),
            },
          });
          break;
        case DeviceAction.DOWNLOAD_LOGS:
          await downloadLogs(details.device.id);
          break;
        case DeviceAction.SHOW_ADVANCED_INFORMATION:
          await getDeviceAdvancedInfo(details.enrollmentCode);
          break;
        case DeviceAction.COLLECT_QUARANTINE_DATA:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item: {
                ...item,
              },
              callback: applyDeviceAction,
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
                "collectQuarantineData"
              ),
            })
          );
          break;
        case DeviceAction.COLLECT_LOGS:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item: {
                ...item,
              },
              callback: applyDeviceAction,
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
                "collectLogs"
              ),
            })
          );
          break;
        case DeviceAction.REMOVE_FROM_PROTECTION:
        case DeviceAction.ENABLE_FIREWALL:
        case DeviceAction.REMOTE_SCAN:
        case DeviceAction.ENFORCE_UAC:
        case DeviceAction.DISABLE_DEVELOPER_MODE:
        case DeviceAction.DISABLE_TAMPER_RESISTANCE:
        case DeviceAction.ALLOW_NO_ENCRYPTION:
        case DeviceAction.STOP_REMOTE_SCAN:
        case DeviceAction.DISABLE_NETWORK_BLOCK_MODE:
        case DeviceAction.ENABLE_NETWORK_BLOCK_MODE:
        case DeviceAction.SHUTDOWN_DEVICE:
        case DeviceAction.REBOOT_DEVICE:
        case DeviceAction.STOP_DLP_SCAN:
        case DeviceAction.UPDATE_AGENT_TO_LATEST:
        case DeviceAction.ENABLE_TAMPER_RESISTANCE:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item: {
                ...item,
              },
              callback: applyDeviceAction,
              disable: actionNotAllowed,
            })
          );
          break;
        case DeviceAction.DLP_PARTIAL_SCAN:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              action,
              item: {
                ...item,
                partialScan: true,
              },
              component: DlpScanModal,
              callback: applyDeviceAction,
              disable: actionNotAllowed,
            })
          );
          break;
        case DeviceAction.MARK_AS_PROCESSED:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                ...item,
                callback: (payload: { action: DeviceAction; enrollmentCodes: string[] }) => {
                  applyDeviceAction({ action, item: { ...payload } });
                },
                modules: [
                  SubscriptionModule.ENDPOINT_SECURITY,
                  SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
                  SubscriptionModule.EDR,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable: actionNotAllowed,
            })
          );
          break;
        case DeviceAction.ADD_LABEL:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                ...item,
              },
              action,
              component: AddOrRemoveDeviceLabelModal,
              width: ModalWidth.SMALL,
              callback: applyDeviceAction,
              disable: actionNotAllowed,
            })
          );
          break;
        case DeviceAction.OPEN_REMOTE_SHELL_SESSION:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              item: {
                ...details,
                ...item,
              },
              action,
              component: MspRemoteShellModal,
              width: 900,
              callback: applyDeviceAction,
              disable: actionNotAllowed,
            }),
            footer: null,
          });
          break;
      }
    }

    /**
     * This method should redirect to tickets log which is filtered by user and vulnerability type
     * @param vulnerability
     */
    function viewVulnerability(vulnerability: TicketTrigger) {
      router.push({
        name: RouteName.MSP_TICKETS_PAGE,
        query: {
          eventTriggers: vulnerability,
          search: deviceDetails.value?.enrollmentCode,
        },
      });
    }

    const isActionDisabled = (action: DeviceAction) => {
      switch (action) {
        case DeviceAction.DLP_PARTIAL_SCAN:
        case DeviceAction.STOP_DLP_SCAN:
          return deviceSettingsDisabled.value;
        case DeviceAction.UPDATE_AGENT_TO_LATEST:
        case DeviceAction.OPEN_REMOTE_SHELL_SESSION:
          return actionNotAllowed;
        default:
          return false;
      }
    };

    const getDeviceActions = async (enrollmentCode: string) => {
      try {
        const payload = {
          enrollmentCodes: [enrollmentCode],
          target: ActionTypeTarget.SINGLE,
        };
        const { data } = await getActions(payload);
        actionsList.value = data.items;
      } catch {
        actionsList.value = [];
      }
    };

    const getDeviceSettings = async (workspaceId: string) => {
      try {
        const { data } = await getMonitoringSettingsByWorkspace(workspaceId);
        deviceSettings.value = data;
      } catch {
        deviceSettings.value = {
          monitorPII: false,
          monitorPCI: false,
          monitorPHI: false,
          monitorNPI: false,
        };
      }
    };

    watch(
      () => props.device,
      async (device) => {
        if (device) {
          await getDeviceDetails(device.enrollmentCode, device.workspaceId);
          await getDeviceActions(device.enrollmentCode);
          await getDeviceSettings(device.workspaceId);
        }
      },
      { immediate: true, deep: true }
    );

    return {
      deviceDetails,
      restrictedTicketModules,
      ticketsAccessRestricted,
      activityLogsAccessRestricted,
      allOpenTicketsLink,
      modulesTooltipText,
      actionsList,
      showIsolationStatus,
      actionNotAllowed,
      deviceDetailsLoading,
      deviceDrivesLoading,
      isMobile,
      onActionClick,
      getFormattedDateTime,
      viewVulnerability,
      undoActivityLog,
      isActionDisabled,
      mergeDuplicateDevices,
      ignoreDuplicateDevicesMerge,
    };
  },
});
</script>
