<template>
  <div>
    <v-data-table
      v-model="selectedItems"
      class="coronet-table--list"
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="pagination.pageSize"
      :server-items-length="totalItems"
      show-select
      return-object
    >
      <template #header.data-table-select>
        <v-checkbox
          v-model="allItemsSelected"
          data-testid="table-select-all-checkbox"
          :indeterminate="isIndeterminate"
          class="ml-3"
          primary
          hide-details
          @update:modelValue="handleAllRowsSelection"
        ></v-checkbox>
      </template>
      <template #header.type>
        <div class="d-flex align-center justify-space-between">
          <list-table-header
            :pagination="pagination"
            :total-items="totalItems"
            :items="items"
            :show-pagination="showPagination"
            @pagination-change="handleListTablePaginationChange"
          >
            <template #prepend>
              <span class="table-selected-text" v-if="selectedItemsQuantity > 0">
                {{ $t("usersPage.selected", { n: selectedItemsQuantity }) }}
              </span>
            </template>
            <template #append>
              <v-menu v-if="selectedItemsQuantity > 0" offset-y location="bottom right">
                <template #activator="{ props }">
                  <v-btn
                    data-testid="bulk-actions-button"
                    class="ml-8"
                    rounded
                    color="primary"
                    v-bind="props"
                    @click="getBulkActions()"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                  </v-btn>
                </template>
                <v-list>
                  <template v-if="availableBulkActions.length === 0">
                    <div v-if="actionsLoading" class="d-flex align-center justify-center">
                      <v-progress-circular
                        size="30"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-list-item v-else data-testid="users-table-no-actions-available-item">
                      <v-list-item-title>
                        {{ $t("general.noActionsAvailable") }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-for="action in availableBulkActions"
                    :key="action"
                    :data-testid="`users-table-${kebabCase(action)}-action`"
                    :disabled="isDLPScanDisabled(action)"
                    @click="onBulkActionClick(action)"
                  >
                    <v-list-item-title>
                      {{ $t(`devices.actions.${action}`) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </list-table-header>
        </div>
      </template>
      <template #item="{ item, isSelected, index, internalItem }">
        <tr
          :key="index"
          :row-disabled="item.offline ? item.offline : null"
          :class="{
            'coro-table-row--selected': isSelected([internalItem]),
            'coro-table-row--active': isRowActive(item[itemKey]),
          }"
          @click="handleRowClick(item)"
        >
          <td class="pl-2">
            <v-checkbox-btn
              :data-testid="`table-checkbox-row-${index}`"
              :model-value="isSelected([internalItem])"
              @update:modelValue="handleOneRowSelection($event, item)"
              @click.stop
            ></v-checkbox-btn>
          </td>
          <td :id="`events-table-type-col-row-${index}`" class="pl-0">
            <div class="list-item-grid py-1">
              <div class="list-item-grid__icon-block">
                <v-icon :icon="getDeviceIcon(item.osType)" size="40" class="mr-4" />
              </div>
              <div class="list-item-grid__details-block d-flex flex-column justify-center pr-2">
                <div :data-testid="`users-table-name-row-${index}`" class="subtitle2">
                  {{ item.lastLoggedInUsers.length ? item.lastLoggedInUsers[0] : item.email }}
                </div>
                <div :data-testid="`users-table-email-row-${index}`" class="body2">
                  <span :data-testid="`devices-table-hostname-col-row-${index}`">
                    {{ item.hostName }}
                  </span>
                  <span
                    v-if="item.offline"
                    class="offline-label"
                    :data-testid="`devices-table-is-offline-row-${index}`"
                    >{{ ` (${$t("general.offline")})` }}
                  </span>
                </div>
                <div
                  class="body3 white-space-normal text-indigo-medium"
                  :class="{
                    'reduced-max-width':
                      showIsolationStatus(item.isolationStatus) || item.labels.length,
                  }"
                  :data-testid="`devices-table-os-col-row-${index}`"
                >
                  {{ item.osDisplayName }}
                </div>
              </div>
              <div class="list-item-grid__status-block">
                <v-tooltip
                  v-if="item.hasOpenTickets || item.outdated"
                  open-delay="300"
                  location="top"
                >
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      icon="$warning"
                      v-bind="props"
                    />
                  </template>
                  {{
                    item.hasOpenTickets
                      ? $t("general.unresolvedTickets")
                      : $t("devices.clientOutdated")
                  }}
                </v-tooltip>
                <v-tooltip v-else open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      v-bind="props"
                      icon="$circleCheck"
                    />
                  </template>
                  {{ $t("general.noTickets") }}
                </v-tooltip>
              </div>
              <div class="list-item-grid__bottom-block">
                <div v-if="item.labels.length" class="d-flex align-center labels-preview">
                  <div v-for="label in getFilteredLabels(item.labels)" :key="label.id">
                    <v-tooltip open-delay="300" location="top">
                      <template #activator="{ props }">
                        <div
                          class="color-preview mr-1"
                          :style="{ 'background-color': label.color }"
                          v-bind="props"
                        />
                      </template>
                      {{ label.name }}
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, toRefs, ref } from "vue";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import { useSelectableTable } from "@/composables/useSelectableTable";
import kebabCase from "lodash/kebabCase";
import { useListTable } from "@/composables/useListTable";
import { ModalWidth } from "@/constants/modals";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import ListTableHeader from "@/components/ListTableHeader.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isModuleDisabled,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import { SubscriptionModule } from "@/constants/workplaces";
import { ActionTypeTarget, DeviceAction, IsolationStatus, OsType } from "@/constants/devices";
import { usePrivacySensitiveDataStore } from "@/_store/endpoint-data-governance/privacy-sensitive-data.module";
import { type DeviceListItem, useDevicesStore } from "@/_store/devices.module";
import type { DeviceLabel } from "@/_store/devices-settings.module";
import { type ISelection, useSelectorStore } from "@/_store/selector.module";
import { useRouter } from "vue-router";
import { TicketTrigger } from "@/constants/tickets";
import { i18n } from "@/plugins/i18n";
import DlpScanModal from "@/components/modals/DlpScanModal.vue";
import AddOrRemoveDeviceLabelModal from "@/components/modals/AddOrRemoveDeviceLabelModal.vue";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: {
    ListTableHeader,
  },
  props: {
    items: {
      type: Array as PropType<DeviceListItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["selection-changed", "page-changed", "update-preview", "update-page-count"],
  setup(props, { emit }) {
    const headers = [
      {
        value: "type",
      },
    ];
    const router = useRouter();
    const itemKey: keyof DeviceListItem = "enrollmentCode";
    const availableBulkActions = ref<DeviceAction[]>([]);
    const actionsLoading = ref(false);
    const dialogStore = useDialogsStore();
    const devicesStore = useDevicesStore();
    const privacySensitiveDataStore = usePrivacySensitiveDataStore();
    const { totalItems, items, pagination } = toRefs(props);

    const { totalPages, page, showPagination, handleListTablePaginationChange } = usePageableTable(
      {
        totalItems,
        items,
        pagination,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );

    const { selection } = storeToRefs(useSelectorStore());

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems,
      items,
    });

    const { handleRowClick, isRowActive, activeRowId } = useListTable<DeviceListItem>(
      { items, itemKey },
      (item) => {
        emit("update-preview", item);
      }
    );

    const getDeviceIcon = (osType?: OsType) => {
      if (osType) {
        const iconMap = {
          [OsType.WINDOWS]: `$windowsPc`,
          [OsType.OSX]: `$macPc`,
          [OsType.IOS]: `$iosDevice`,
          [OsType.ANDROID]: `$androidDevice`,
          [OsType.MAC_OS]: `$macPc`,
        };
        return iconMap[osType];
      }
      return undefined;
    };

    const onBulkActionClick = async (action: DeviceAction) => {
      const item = {
        action,
        selection: { ...selection.value },
      };

      const disable = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.VIEWS,
        "devicesView"
      );
      switch (action) {
        case DeviceAction.EXAMINE_MALWARE:
          await router.push({
            name: "tickets",
            query: {
              eventTriggers: TicketTrigger.MALWARE_ON_ENDPOINT,
            },
          });
          break;
        case DeviceAction.MARK_AS_PROCESSED:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                ...item,
                callback: (payload: { action: DeviceAction; selection: ISelection }) => {
                  devicesStore.applyDeviceAction({
                    action,
                    item: { ...payload },
                  });
                },
                modules: [
                  SubscriptionModule.ENDPOINT_SECURITY,
                  SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
                  SubscriptionModule.EDR,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable,
            })
          );
          break;
        case DeviceAction.DLP_PARTIAL_SCAN:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              action,
              item: {
                ...item,
                partialScan: true,
              },
              component: DlpScanModal,
              callback: devicesStore.applyDeviceAction,
              disable,
            })
          );
          break;
        case DeviceAction.ADD_LABEL:
        case DeviceAction.REMOVE_LABEL:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item,
              action,
              component: AddOrRemoveDeviceLabelModal,
              width: ModalWidth.SMALL,
              callback: devicesStore.applyDeviceAction,
              disable,
            })
          );
          break;
        case DeviceAction.EXPORT_TO_CSV:
          await devicesStore.exportDevicesToCsv({ item });
          break;
        default:
          dialogStore.openDialog({
            ...confirmationDialogsConfigConstructor({
              action,
              disable,
              item,
              callback: devicesStore.applyDeviceAction,
            }),
            content: {
              html: i18n.global.t(
                `modals.${action}.description`,
                { quantity: selectedItemsQuantity.value },
                selectedItemsQuantity.value
              ),
            },
          });
          break;
      }
    };

    const isDLPScanDisabled = (action: DeviceAction): boolean => {
      if ([DeviceAction.DLP_PARTIAL_SCAN, DeviceAction.STOP_DLP_SCAN].includes(action)) {
        return privacySensitiveDataStore.deviceSettingsDisabled;
      }
      return false;
    };

    const showIsolationStatus = (status: IsolationStatus): boolean =>
      [IsolationStatus.ISOLATED].includes(status);
    /**
     * Filters the labels that are not predefined and returns only first 5 of them.
     * @param labels
     */
    const getFilteredLabels = (labels: DeviceLabel[]): DeviceLabel[] =>
      labels.filter((label) => !label.predefined).slice(0, 5);

    const getBulkActions = async () => {
      availableBulkActions.value = [];
      actionsLoading.value = true;
      try {
        const payload = {
          selection: selection.value,
          target: ActionTypeTarget.BULK,
        };
        const { data } = await devicesStore.getActions(payload);
        availableBulkActions.value = data.items;
        actionsLoading.value = false;
      } catch {
        actionsLoading.value = false;
      }
    };

    onMounted(async () => {
      if (!isModuleDisabled(SubscriptionModule.ENDPOINT_DATA_GOVERNANCE)) {
        await privacySensitiveDataStore.getSettings();
      }
    });

    return {
      headers,
      itemKey,
      totalPages,
      page,
      kebabCase,
      handleRowClick,
      isRowActive,
      activeRowId,
      selectedItems,
      showPagination,
      onBulkActionClick,
      handleListTablePaginationChange,
      isDLPScanDisabled,
      showIsolationStatus,
      getFilteredLabels,
      getDeviceIcon,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      availableBulkActions,
      getBulkActions,
      actionsLoading,
    };
  },
});
</script>

<style lang="scss">
.coronet-table--list table span.caption.isolation-status,
.labels-preview {
  color: rgb(var(--v-theme-red-dark)) !important;
}
.status-icon {
  position: absolute;
  top: 0;
  right: 0;
}

// calculate max width depending if the isolation status or labels are shown
.reduced-max-width {
  max-width: calc(100% - 56px);
  min-width: 60px;
}

.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}

td {
  position: relative;
}
</style>
