<template>
  <div>
    <div class="d-flex align-center justify-between w-100 mb-4">
      <div class="subtitle1 flex-grow-1">
        {{ $t(`accessControlPage.roles.scopes.${scopeName}.title`) }}
        <v-tooltip v-if="showDetailsTooltip" open-delay="300" location="top">
          <template #activator="{ props }">
            <v-icon v-bind="props" color="primary" icon="$info" />
          </template>
          {{ $t(`modals.addMspRole.tooltips.${scopeName}`) }}
        </v-tooltip>
      </div>

      <div>
        <v-select
          v-model="bulkActionSelectModel"
          variant="outlined"
          density="compact"
          rounded
          reverse
          min-width="130"
          :items="permissionOptions"
          :placeholder="$t('general.select')"
          :label="bulkActionSelectModel ? '' : $t('general.select')"
          @update:modelValue="onBulkActionSelect($event)"
          hide-details
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="$t(`accessControlPage.roles.details.permissionModes.${item.raw}`)"
            />
          </template>
          <template v-slot:selection="{ item }">
            {{ $t(`accessControlPage.roles.details.permissionModes.${item.raw}`) }}
          </template>
        </v-select>
      </div>
    </div>
    <table class="permission-table">
      <template v-for="(value, name) in model[scopeName]" :key="name">
        <template v-if="showModulesDisabledBySubscription || showPermission(name)">
          <tr>
            <td class="pl-4 py-4">
              <span class="subtitle1">
                {{
                  isNestedPermission(name, value)
                    ? $t(`accessControlPage.roles.scopes.${scopeName}.${name}.title`)
                    : $t(`accessControlPage.roles.scopes.${scopeName}.${name}`)
                }}
              </span>
            </td>
            <td class="pr-2 py-4 w-20">
              <div class="d-flex justify-end">
                <template v-if="isNestedPermission(name, value)">
                  <v-select
                    :model-value="value.accessMode"
                    variant="underlined"
                    density="compact"
                    reverse
                    :items="permissionOptions"
                    :rules="[required()]"
                    :placeholder="$t('general.select')"
                    :label="value.accessMode ? '' : $t('general.select')"
                    @update:modelValue="
                      item[scopeName][name].accessMode = $event;
                      resetBulkActionSelectValue();
                    "
                    hide-details
                  >
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="$t(`accessControlPage.roles.details.permissionModes.${item.raw}`)"
                      />
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ $t(`accessControlPage.roles.details.permissionModes.${item.raw}`) }}
                    </template>
                  </v-select>
                </template>
                <v-select
                  v-else
                  :model-value="value"
                  reverse
                  variant="underlined"
                  density="compact"
                  :items="permissionOptions"
                  :rules="[required()]"
                  :placeholder="$t('general.select')"
                  :label="value ? '' : $t('general.select')"
                  @update:modelValue="
                    item[scopeName][name] = $event;
                    resetBulkActionSelectValue();
                  "
                  hide-details
                >
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                      v-bind="props"
                      :title="$t(`accessControlPage.roles.details.permissionModes.${item.raw}`)"
                    />
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ $t(`accessControlPage.roles.details.permissionModes.${item.raw}`) }}
                  </template>
                </v-select>
              </div>
            </td>
          </tr>
          <tr
            v-if="
              isNestedPermission(name, value) && value.accessMode === RolePermissionAccessMode.EDIT
            "
          >
            <td colspan="2">
              <template
                v-for="(permissionValue, permissionName) in value.editAccessModePermission"
                :key="permissionName"
              >
                <v-checkbox
                  v-if="permissionName !== 'assignRoles'"
                  class="ml-8"
                  :model-value="permissionValue"
                  density="compact"
                  :label="
                    $t(
                      `accessControlPage.roles.scopes.${scopeName}.${name}.editAccessModePermission.${permissionName}`
                    )
                  "
                  @update:modelValue="
                    item[scopeName][name].editAccessModePermission[permissionName] = $event
                  "
                />
              </template>
            </td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType, ref, watch } from "vue";
import type {
  MspRolePermissionScopes,
  NestedPermissions,
  RolePermissionsScope,
} from "@/_store/roles.module";
import { required } from "@/_helpers/validators";
import { useRolesPermissions } from "@/composables/useRolesPermissions";
import { RolePermissionAccessMode } from "@/constants/roles";
import defaultsDeep from "lodash/defaultsDeep";

const permissionOptions = [
  RolePermissionAccessMode.EDIT,
  RolePermissionAccessMode.VIEW,
  RolePermissionAccessMode.NO_ACCESS,
];

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    scopeName: {
      type: String as PropType<RolePermissionsScope | MspRolePermissionScopes>,
      required: true,
    },
    emptyPermissionsObject: {
      type: Object,
      required: true,
    },
    showModulesDisabledBySubscription: {
      type: Boolean,
      default: false,
    },
    showDetailsTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:item"],
  setup(props, { emit }) {
    const { isNestedPermission, showPermission } = useRolesPermissions(props.scopeName);
    const model = ref(defaultsDeep(props.item, props.emptyPermissionsObject));
    const bulkActionSelectModel = ref<RolePermissionAccessMode>();
    const onBulkActionSelect = (accessMode: RolePermissionAccessMode) => {
      const scope = model.value[props.scopeName];
      Object.entries(scope).forEach(([name, value]) => {
        if (isNestedPermission(name, value as NestedPermissions | RolePermissionAccessMode)) {
          scope[name].accessMode = accessMode;
        } else {
          scope[name] = accessMode;
        }
      });
    };

    const resetBulkActionSelectValue = () => {
      bulkActionSelectModel.value = undefined;
    };

    watch(
      model,
      (value) => {
        emit("update:item", value);
      },
      { deep: true }
    );

    return {
      model,
      permissionOptions,
      RolePermissionAccessMode,
      bulkActionSelectModel,
      isNestedPermission,
      required,
      showPermission,
      onBulkActionSelect,
      resetBulkActionSelectValue,
    };
  },
});
</script>
<style lang="scss" scoped>
.permission-table {
  border-radius: 8px;
  border-spacing: 0;
  width: 100%;
  border: 1px solid rgb(var(--v-theme-indigo-pale)) !important;

  td {
    border-bottom: 1px solid rgb(var(--v-theme-indigo-pale)) !important;
  }

  tr:last-child > td {
    border-bottom: 0 !important;
  }
}

:deep(*) {
  .v-field__outline {
    &:before,
    &:after {
      border: 0 !important;
    }
  }
}
</style>
