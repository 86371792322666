import { ActionTypeTarget, DeviceAction } from "@/constants/devices";
import { type DeviceLabel } from "@/_store/devices-settings.module";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useFiltersStore } from "@/_store/filters.module";
import { isAccessRestricted } from "@/_helpers/utils";
import { i18n } from "@/plugins/i18n";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useSnackbarStore } from "@/_store/snackbar.module";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { Pagination } from "@/types";
import { SubscriptionModule } from "@/constants/workplaces";
import { StoreModule } from "@/constants/store.ts";
import type { DeviceDetails, DeviceListItem, DevicesState } from "@/_store/devices.module.ts";
import { useMspTerminalStore } from "@/_store/msp/msp-terminal.module.ts";

export interface MspDeviceListItem extends DeviceListItem {
  workspaceName: string;
  workspaceId: string;
}

export interface MspDeviceDetails extends DeviceDetails {
  workspaceName: string;
  workspaceId: string;
}

export type MspDeviceStatusFilterItem = (
  | { name: string; color: string; id: string }
  | DeviceLabel
) & {
  statusLabel: boolean;
};

export interface MspDevicesState extends DevicesState {
  deviceDetails: MspDeviceDetails;
  devices: MspDeviceListItem[];
  availableLabels: DeviceLabel[];
}

const defaultDevicesSettingsState: MspDevicesState = {
  statuses: [],
  devices: [],
  pagination: {
    page: 0,
    pageSize: 25,
  },
  showSkeletonLoader: true,
  loading: true,
  totalDevices: 0,
  deviceDetailsLoading: false,
  activityLogsLoading: false,
  clientVersions: {},
  osVersions: {},
  availableLabels: [],
  deviceDrivesLoading: false,
  deviceDetails: {
    antiTamperingProtectionEnabled: false,
    appOutdated: false,
    blockModeEnabled: false,
    deviceModelId: "",
    deviceSerialNumber: "",
    dlpScanInProgress: false,
    isolationStatus: undefined,
    labels: [],
    lastKnownIp: "",
    lastLogsUploadedAt: 0,
    logsUploaded: false,
    machineId: "",
    malwareScanInProgress: false,
    marketingVersion: "",
    offline: false,
    osDisplayName: "",
    osVersion: "",
    rebootRequired: false,
    removed: false,
    upn: "",
    vpnIp: "",
    name: "",
    email: "",
    enrollmentCode: "",
    osType: undefined,
    lastActivity: 0,
    firstActivity: 0,
    lastRemoteScan: 0,
    appVersion: "",
    vulnerabilities: [],
    device: { id: "", model: "", hostname: "" },
    tickets: [],
    activityLogs: [],
    lastLoggedInUsers: [],
    drives: [],
    activationTime: 0,
    workspaceId: "",
    workspaceName: "",
  },
};

export const useMspDevicesStore = defineStore(StoreModule.MSP_DEVICES, {
  state: () => ({ ...defaultDevicesSettingsState }),
  actions: {
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = { ...defaultDevicesSettingsState.pagination };
    },
    async init() {
      return await Promise.all([
        this.getOSVersions(),
        this.getLabels(),
        this.getClientVersions(),
        this.getDeviceStatuses(),
      ]);
    },
    async getDevices(showSkeletonLoader = false) {
      this.loading = true;
      this.showSkeletonLoader = showSkeletonLoader;
      const filtersStore = useFiltersStore();
      const {
        hasVulnerabilities,
        osType,
        osVersion,
        clientVersion,
        vulnerabilities,
        search,
        labels: groups,
        widget,
        childWorkspaceIds,
      } = filtersStore.filters.mspDevices;
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(groups);
      const request = {
        ...api.mspDevices(),
        params: {
          vulnerabilities: vulnerabilities?.join(","),
          hasVulnerabilities,
          osType,
          osVersion,
          deviceStatuses: deviceStatuses?.join(","),
          clientVersion,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          labels: labels?.join(","),
          search,
          widget,
          childWorkspaceIds: childWorkspaceIds?.join(","),
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.devices = data.items;
        this.totalDevices = data.total;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
      this.showSkeletonLoader = false;
    },
    async getDeviceDetails(enrollmentCode: string, workspaceId: string) {
      this.deviceDetailsLoading = true;
      const deviceInfoRequest = {
        ...api.getMspDevice(enrollmentCode, workspaceId),
      };
      const deviceTicketsRequest = {
        ...api.getMspDeviceTickets(enrollmentCode, workspaceId),
      };
      const deviceActivityLogsRequest = {
        ...api.getMspDeviceActivityLogs(enrollmentCode, workspaceId),
        params: {
          page: 0,
          pageSize: 5,
        },
      };
      const activityLogsAccessRestricted = isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      );
      const ticketsRestricted = (
        [
          SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
          SubscriptionModule.EDR,
          SubscriptionModule.ENDPOINT_SECURITY,
        ] as const
      ).every((module) => isAccessRestricted(RolePermissionsScope.TICKETS, module));
      Promise.all([
        axiosInstance.request(deviceInfoRequest),
        ticketsRestricted ? null : axiosInstance.request(deviceTicketsRequest),
        activityLogsAccessRestricted ? null : axiosInstance.request(deviceActivityLogsRequest),
      ])
        .then(([deviceInfoResponse, deviceTicketsResponse, deviceActivityLogsResponse]) => {
          const activityLogs = deviceActivityLogsResponse
            ? [...deviceActivityLogsResponse.data.items]
            : [];
          this.deviceDetails = {
            ...deviceInfoResponse.data,
            tickets: deviceTicketsResponse ? [...deviceTicketsResponse.data] : [],
            activityLogs,
          };
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.deviceDetailsLoading = false;
        });
    },
    async undoActivityLog(enrollmentCode: string, activityLogId: string) {
      this.activityLogsLoading = true;

      try {
        const request = { ...api.activityLogsUndo(activityLogId) };

        await axiosInstance.request(request);

        const deviceActivityLogsRequest = {
          ...api.getDeviceActivityLogs(enrollmentCode),
          params: {
            page: 0,
            pageSize: 5,
          },
        };

        const { data } = await axiosInstance.request(deviceActivityLogsRequest);
        this.deviceDetails.activityLogs = data.items;
        await this.getDeviceDetails(enrollmentCode, this.deviceDetails.workspaceId);
      } catch (error) {
        console.error(error);
      }
      this.activityLogsLoading = false;
    },
    async downloadLogs(deviceId: string) {
      const request: AxiosRequestConfig = {
        ...api.getDevicesLogs(deviceId),
        responseType: "arraybuffer",
      };
      useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.devices.downloadLogs"));

      axiosInstance
        .request(request)
        .then(async (response) => {
          const blob = new Blob([response.data], { type: "octet/stream" });
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", downloadUrl);
          link.setAttribute("download", `logs-${deviceId}.zip`);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async openRemoteShellSession(payload: {
      action: DeviceAction;
      enrollmentCodes: string[];
      sshSessionId?: string;
    }) {
      const requestData = {
        action: payload.action,
        enrollmentCodes: payload.enrollmentCodes,
      };
      const request = {
        ...api.mspDevicesAction,
        data: requestData,
      };

      return axiosInstance
        .request(request)
        .then((response) => {
          const firstDeviceEnrollmentCode = Object.keys(response.data.actionResults)[0];
          const terminalStore = useMspTerminalStore();
          terminalStore.setSessionId(response.data.actionResults[firstDeviceEnrollmentCode]);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async closeRemoteShellSession({
      action,
      enrollmentCodes,
      sshSessionId,
    }: {
      action: DeviceAction;
      enrollmentCodes: string[];
      sshSessionId: string;
    }) {
      const requestData = {
        action,
        enrollmentCodes,
        metadata: {
          sshSessionId,
        },
      };
      const request = {
        ...api.mspDevicesAction,
        data: requestData,
      };
      useMspTerminalStore().resetState();
      return axiosInstance.request(request).catch((err) => {
        console.error(err);
      });
    },
    async applyDeviceAction(payload: {
      action?: DeviceAction;
      item: {
        action: DeviceAction;
        enrollmentCodes: string[];
        partialScan?: boolean;
        metadata?: {
          [key: string]: unknown;
        };
      };
    }) {
      const action = payload.action || payload.item.action;
      const snackbarMessage = (quantity: number) =>
        i18n.global.t(`snackbar.messages.event.${action}`, { quantity }, quantity);
      const filtersStore = useFiltersStore();
      const devicesFilters = { ...filtersStore.filters.mspDevices };
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(devicesFilters.labels);
      const requestData: {
        action: DeviceAction;
        enrollmentCodes: string[];
        filter?: { [key: string]: unknown };
        metadata?: { [key: string]: unknown };
      } = {
        action,
        enrollmentCodes: payload.item.enrollmentCodes,
        filter: {
          ...devicesFilters,
          labels,
          deviceStatuses,
        },
      };
      if (payload.item.metadata) {
        requestData.metadata = payload.item.metadata;
      }
      if (action === DeviceAction.DLP_PARTIAL_SCAN && !payload.item.partialScan) {
        requestData.action = DeviceAction.DLP_FULL_SCAN;
      }

      const request = {
        ...api.mspDevicesAction,
        data: requestData,
      };
      try {
        const { data } = await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(snackbarMessage(data.appliedActions));
        await this.getDevices();
      } catch (e) {
        console.error(e);
      }
    },
    async exportDevicesToCsv(payload: {
      item: {
        enrollmentCodes: string[];
      };
    }) {
      const filtersStore = useFiltersStore();
      const devicesFilters = { ...filtersStore.filters.mspDevices };
      const { labels, deviceStatuses } = convertDevicesGroupsFilter(devicesFilters.labels);
      const requestData: {
        enrollmentCodes: string[];
        filter: { [key: string]: unknown };
      } = {
        enrollmentCodes: payload.item.enrollmentCodes,
        filter: {
          ...devicesFilters,
          labels,
          deviceStatuses,
        },
      };
      const request = {
        ...api.mspDevicesCsvExport,
        data: requestData,
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async getOSVersions() {
      const request = {
        ...api.mspDevicesOsVersions,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.osVersions = data;
      } catch (err) {
        console.error(err);
      }
    },
    async getClientVersions() {
      const request = {
        ...api.mspDevicesClientVersions,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.clientVersions = data;
      } catch (err) {
        console.error(err);
      }
    },
    async getLabels() {
      const request = {
        ...api.mspDeviceLabels,
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.availableLabels = data;
      } catch (err) {
        console.error(err);
      }
    },
    async encryptDrive(payload: { enrollmentCode: string; driveName: string }) {
      const actionPayload = {
        action: DeviceAction.ENCRYPT_DRIVE,
        item: {
          action: DeviceAction.ENCRYPT_DRIVE,
          enrollmentCodes: [payload.enrollmentCode],
          metadata: {
            driveLetter: payload.driveName,
          },
        },
      };
      await this.applyDeviceAction(actionPayload);
    },
    async getDeviceStatuses() {
      const request = {
        ...api.mspDevicesStatuses,
      };
      try {
        const { data } = await axiosInstance.request(request);
        // create an ID and a flag that will indicate this is a status label as statuses are used in same dropdown as labels
        this.statuses = (data ?? []).map(
          (v: Pick<MspDeviceStatusFilterItem, "name" | "color">) => ({
            ...v,
            id: v.name,
            statusLabel: true,
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    async getDeviceAdvancedInfo(enrollmentCode: string) {
      const request = {
        ...api.mspDeviceAdvancedInfo(enrollmentCode, this.deviceDetails.workspaceId),
      };

      try {
        const { data } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: "text/xml" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url);
      } catch (err) {
        console.error(err);
      }
    },
    async mergeDuplicateDevices(enrollmentCode: string) {
      const request = {
        ...api.mspMergeDuplicateDevices(enrollmentCode, this.deviceDetails.workspaceId),
      };

      try {
        await axiosInstance.request(request);
        await this.getDeviceDetails(enrollmentCode, this.deviceDetails.workspaceId);
        await this.getDevices();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.devices.mergeSuccessful")
        );
      } catch (err) {
        console.error(err);
      }
    },
    async ignoreDuplicateDevicesMerge(enrollmentCode: string) {
      const request = {
        ...api.mspIgnoreDuplicateDevicesMerge(enrollmentCode, this.deviceDetails.workspaceId),
      };

      try {
        await axiosInstance.request(request);
        await this.getDeviceDetails(enrollmentCode, this.deviceDetails.workspaceId);
      } catch (err) {
        console.error(err);
      }
    },
    getActions({
      enrollmentCodes,
      target,
    }: {
      enrollmentCodes: string[];
      target: ActionTypeTarget;
    }): Promise<AxiosResponse<{ items: DeviceAction[] }>> {
      const filters = useFiltersStore();
      const request = {
        ...api.availableMspDeviceActions,
        data: {
          filters: {
            ...filters.filters.mspDevices,
          },
          enrollmentCodes,
          target,
        },
      };

      return axiosInstance.request(request);
    },
    getMonitoringSettingsByWorkspace(workspaceId: string) {
      const request = {
        ...api.getMonitoringSettingsByWorkspace,
        params: {
          workspaceId,
        },
      };
      return axiosInstance.request(request);
    },
    getDeviceSettingsByWorkspace(workspaceId: string) {
      const request = {
        ...api.getDeviceSettingsByWorkspace,
        params: {
          workspaceId,
        },
      };
      return axiosInstance.request(request);
    },
  },
});

export function convertDevicesGroupsFilter(groups: MspDeviceStatusFilterItem[] | undefined = []): {
  deviceStatuses: string[];
  labels: string[];
} {
  const deviceStatuses = (groups ?? []).filter((group) => group.statusLabel).map((v) => v.name);
  const labels = (groups ?? []).filter((group) => !group.statusLabel).map((v) => v.id);
  return {
    deviceStatuses,
    labels,
  };
}
