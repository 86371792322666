import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Pagination } from "@/types";
import type { DeviceLabel } from "@/_store/devices-settings.module";
import type { EncryptionStrength } from "@/constants/network";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";

export interface VirtualOfficeStoreState {
  settings: VirtualOfficeSettings;
  includedUrlList: VirtualOfficeURLListItem[];
  excludedUrlList: VirtualOfficeURLListItem[];
  totalIncluded: number;
  totalExcluded: number;
  urlListLoading: boolean;
  showSkeletonLoader: boolean;
  fetchingSettings: boolean;
  vpnConnectionStatus: VpnConnectionStatus;
  mobileBannerConfiguration: MobileBannerConfiguration;
  encryptionLoading: boolean;
  includedPagination: Pagination;
  excludedPagination: Pagination;
}

export interface MobileBannerConfiguration {
  visible: boolean;
}

export interface VpnConnectionStatus {
  configured?: boolean;
  connected?: boolean;
}

export interface VirtualOfficeURLListItem {
  urlName: string;
  excluded: boolean;
}

export interface VirtualOfficeSettings {
  fullTunneling: boolean;
  region: string;
  servers: string[];
  mode2Labels: DeviceLabel[];
  mode3Labels: DeviceLabel[];
  manualDisconnectionEnabled: boolean;
  manualDisconnectionLabels: DeviceLabel[];
  blockLocalNetworkEnabled: boolean;
  blockLocalNetworkLabels: DeviceLabel[];
  dataCiphers: EncryptionStrength;
}

const defaultVirtualOfficeState: VirtualOfficeStoreState = {
  settings: {} as VirtualOfficeSettings,
  includedUrlList: [],
  excludedUrlList: [],
  totalIncluded: 0,
  totalExcluded: 0,
  urlListLoading: false,
  showSkeletonLoader: false,
  fetchingSettings: false,
  vpnConnectionStatus: {},
  mobileBannerConfiguration: {} as MobileBannerConfiguration,
  encryptionLoading: false,
  includedPagination: {
    page: 0,
    pageSize: 10,
  },
  excludedPagination: {
    page: 0,
    pageSize: 10,
  },
};

export const useVirtualOfficeStore = defineStore("virtualOffice", {
  state: () => ({ ...defaultVirtualOfficeState }),
  getters: {
    vpnConnected(state) {
      return state.vpnConnectionStatus?.connected ?? false;
    },
  },
  actions: {
    async getMobileBannerConfiguration() {
      const requestConfig = api.getMobileBannerConfiguration();

      try {
        const { data } = await axiosInstance.request(requestConfig);
        this.mobileBannerConfiguration = data;
      } catch (e) {
        console.error(e);
      }
    },

    async updateMobileBannerConfiguration(payload: { visible: boolean }): Promise<void> {
      const requestConfig = api.updateMobileBannerConfiguration(payload);

      try {
        await axiosInstance.request(requestConfig);
        await this.getMobileBannerConfiguration();
      } catch (e) {
        console.error(e);
      }
    },

    async getVpnSettings(): Promise<void> {
      const requestConfig = api.getVpnSettings();
      try {
        this.fetchingSettings = true;
        const { data } = await axiosInstance.request(requestConfig);
        this.settings = data;
      } catch (e) {
        console.error(e);
      } finally {
        this.fetchingSettings = false;
      }
    },

    async updateVpnSettings(payload: VirtualOfficeSettings): Promise<void> {
      return await axiosInstance.request({
        ...api.updateVpnSettings(payload),
      });
    },

    async getVpnUrlList({ excluded = false }): Promise<void> {
      const filters =
        useFiltersStore().filters[
          excluded ? FilterContext.VIRTUAL_OFFICE_EXCLUDED : FilterContext.VIRTUAL_OFFICE_INCLUDED
        ];
      const pagination: Pagination = excluded ? this.excludedPagination : this.includedPagination;
      const requestConfig = api.getVpnUrlList({
        excluded,
        ...pagination,
        ...filters,
      });

      try {
        const {
          data: { items, total },
        } = await axiosInstance.request(requestConfig);

        const urls = items.map((url: { urlName: string; excluded: boolean }) => ({
          ...url,
          id: `${url.urlName}-${url.excluded}`,
        }));
        if (excluded) {
          this.excludedUrlList = [...urls];
          this.totalExcluded = total;
        } else {
          this.includedUrlList = [...urls];
          this.totalIncluded = total;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async setupVirtualOffice(
      payload: DialogDataConfig<{
        region: { value: string; displayValue: string };
      }>
    ) {
      const region = payload.item.region.value;
      const request = {
        ...api.setupVirtualOffice({
          region,
        }),
      };

      try {
        await axiosInstance.request(request);
        await this.getVpnConnection();
      } catch (err) {
        console.error(err);
      }
    },
    async getVpnConnection() {
      try {
        const request = {
          ...api.vpnSetup,
        };
        this.showSkeletonLoader = true;
        const { data } = await axiosInstance.request(request);

        this.vpnConnectionStatus = data;
        this.showSkeletonLoader = false;
      } catch (error) {
        console.error(error);
      }
    },

    async removeFromVpnUrlList(payload: { urlName: string; excluded: boolean }) {
      const requestConfig = api.removeFromVpnUrlList(payload);
      try {
        this.urlListLoading = true;
        await axiosInstance.request(requestConfig);
        this.includedPagination = { ...defaultVirtualOfficeState.includedPagination };
        await this.getVpnUrlList({ excluded: payload.excluded });
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async addUrlToUrList(payload: { items: string[]; excluded: boolean }) {
      const requestConfig = api.addToVpnUrlList(payload);
      this.includedPagination = { ...defaultVirtualOfficeState.includedPagination };
      try {
        await axiosInstance.request(requestConfig);
        await this.getVpnUrlList({ excluded: payload.excluded });
      } catch (e) {
        console.error(e);
      }
    },

    async getVpnRegions() {
      const request = {
        ...api.vpnRegions,
      };

      try {
        const { data } = await axiosInstance.request<Record<string, string>>(request);
        return data;
      } catch (e) {
        console.error(e);
        return {};
      }
    },
  },
});
