<template>
  <div class="d-flex flex-column">
    <div class="vpn-policy">
      <div class="ips-data mb-3">
        <div class="headline6 mb-2" v-text="localValue.region" />
        <div class="body1">
          <span class="text--semitransparent mr-3">
            {{ $t("network.virtualOffice.ipAddresses", localValue.servers?.length) }}:
            {{ ips }}</span
          >
          <span
            v-if="hasManyServers"
            role="button"
            class="body2 coro-link"
            @click="onShowAllIpsClick"
            >&nbsp;{{ $t("general.viewAll") }} ›</span
          >
        </div>
      </div>
      <v-card class="mb-8">
        <v-card-text>
          <div class="d-flex align-center pa-4">
            <v-icon icon="$defaultVpn" class="section-icon mr-4"></v-icon>
            <div>
              <div class="subtitle1 mb-1" v-text="$t('network.virtualOffice.defaultPolicy')"></div>
              <span class="body2" v-text="$t('network.virtualOffice.defaultPolicyDescription')" />
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mb-8">
        <v-card-text>
          <div class="d-flex pa-4">
            <v-icon icon="$splitTunneling" class="section-icon mr-4"></v-icon>
            <div class="mt-6 w-100">
              <div class="subtitle1 mb-1" v-text="$t('network.virtualOffice.splitTunneling')"></div>
              <span class="body2" v-text="$t('network.virtualOffice.mode2Description')" />
              <div>
                <div>
                  <v-autocomplete
                    v-model="localValue.mode2Labels"
                    class="mt-4"
                    :items="labels"
                    :label="autocompleteLabel(localValue?.mode2Labels?.length)"
                    :placeholder="$t('network.virtualOffice.splitTunnelingLabelsPlaceholder')"
                    :disabled="actionNotAllowed"
                    variant="outlined"
                    multiple
                    return-object
                    chips
                    closable-chips
                    clear-on-select
                    data-testid="device-settings-tamper-resistance-labels"
                    item-value="id"
                    item-title="name"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :closable="true"
                        variant="flat"
                        rounded="sm"
                        size="default"
                        :color="item.raw.color"
                        close-icon="$close"
                        :text="item.raw.name"
                        class="label-chip"
                      >
                        <div class="d-flex align-center">
                          <span class="ml-1 mr-2 body2" v-text="item.title" />
                        </div>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item, props }">
                      <v-list-item v-bind="props" title="">
                        <template #default="{ isSelected }">
                          <div
                            class="d-flex align-center label-popup-block"
                            @click="onLabelListItemClick(item, $event, 'mode2Labels', isSelected)"
                          >
                            <div
                              class="color-preview mr-2"
                              :style="{ 'background-color': item.raw.color }"
                            />
                            <span v-text="item.raw.name" />
                          </div>
                        </template>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <v-expansion-panels flat hide-actions v-model="expandedIncludePanel">
            <v-expansion-panel>
              <template #title>
                <div>
                  <v-icon
                    v-if="totalIncluded"
                    icon="$chevron"
                    :class="['text--semibold ml-2', { rotated: expandedIncludePanel === 0 }]"
                  />
                  <v-icon icon="$includedResources" class="table-header-icon mr-2" />
                  <span class="subtitle1">
                    {{ $t("network.virtualOffice.includedExternalResources") }}
                  </span>
                  <span v-if="totalIncluded" class="subtitle1"> ({{ totalIncluded }}) </span>
                </div>
                <div class="d-flex align-center">
                  <filter-wrapper
                    class="mr-2"
                    :show-clear-button="showIncludedFiltersButton"
                    @clear-filters-clicked="onClearIncludedFilters()"
                  >
                    <v-text-field
                      v-model.trim="localIncludedFilters.search"
                      :class="['mr-3', { 'filter-active': localIncludedFilters.search?.length }]"
                      dense
                      density="compact"
                      :placeholder="$t('general.search')"
                      class="search-field"
                      prepend-inner-icon="icon-search"
                      clearable
                      clear-icon="icon-x"
                      hide-details
                      @click.stop
                    />
                  </filter-wrapper>
                  <v-menu>
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="add-btn"
                        color="primary"
                        rounded
                        size="large"
                        @click="
                          onActionClick({
                            action: VirtualOfficeAction.ADD_URL_TO_URL_LIST,
                            item: {
                              excluded: false,
                            },
                          })
                        "
                      >
                        <v-icon icon="$add" color="white" />
                        <span v-text="$t('general.add')" />
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </template>
              <template #text>
                <virtual-office-tab-url-table
                  v-if="includedUrlList.length"
                  :pagination="includedPagination"
                  :total-items="totalIncluded"
                  :items="includedUrlList"
                  @remove-url="onActionClick($event)"
                  @page-change="onPageChange(false, $event)"
                />
              </template>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>

      <v-card class="full-tunneling-card-action">
        <div class="d-flex justify-end">
          <span
            class="coro-link pt-4 pr-4"
            @click="onActionClick({ action: VirtualOfficeAction.TOGGLE_FULL_TUNNELING })"
            >{{
              localValue.fullTunneling
                ? $t("network.virtualOffice.deActivateFullTunnelingVpn")
                : $t("network.virtualOffice.activateFullTunnelingVpn")
            }}
            ></span
          >
        </div>
      </v-card>
      <v-card class="mb-8 full-tunneling-card" :disabled="!localValue.fullTunneling">
        <v-card-text>
          <div :class="['d-flex pa-4 pt-0 pt-0', { 'pb-8': !localValue.fullTunneling }]">
            <v-icon icon="$fullTunneling" class="section-icon mr-6"></v-icon>
            <div class="mt-6 w-100">
              <div class="subtitle1 mb-1" v-text="$t('network.virtualOffice.fullTunneling')"></div>
              <span class="body2" v-text="$t('network.virtualOffice.mode1Description')" />
              <div>
                <div v-if="localValue.fullTunneling">
                  <v-autocomplete
                    v-model="localValue.mode3Labels"
                    class="mt-4"
                    :items="labels"
                    :label="autocompleteLabel(localValue?.mode3Labels?.length)"
                    :placeholder="$t('network.virtualOffice.fullTunnelingLabelsPlaceholder')"
                    :disabled="actionNotAllowed"
                    variant="outlined"
                    multiple
                    return-object
                    chips
                    closable-chips
                    clear-on-select
                    item-value="id"
                    item-title="name"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :closable="true"
                        variant="flat"
                        rounded="sm"
                        size="default"
                        density="comfortable"
                        :color="item.raw.color"
                        close-icon="$close"
                        :text="item.raw.name"
                        class="label-chip"
                      >
                        <div class="d-flex align-center">
                          <span class="ml-1 mr-2 body2" v-text="item.title" />
                        </div>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item, props }">
                      <v-list-item v-bind="props" title="">
                        <template #default="{ isSelected }">
                          <div
                            class="d-flex align-center label-popup-block"
                            @click="onLabelListItemClick(item, $event, 'mode3Labels', isSelected)"
                          >
                            <div
                              class="color-preview mr-2"
                              :style="{ 'background-color': item.raw.color }"
                            />
                            <span v-text="item.raw.name" />
                          </div>
                        </template>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <v-expansion-panels
            flat
            hide-actions
            v-model="expandedExcludePanel"
            v-if="localValue.fullTunneling"
          >
            <v-expansion-panel>
              <template #title>
                <div>
                  <v-icon
                    v-if="totalExcluded"
                    icon="$chevron"
                    :class="['text--semibold ml-2', { rotated: expandedExcludePanel === 0 }]"
                  />
                  <v-icon icon="$defaultVpn" class="table-header-icon mr-2" />
                  <span class="subtitle1">{{
                    $t("network.virtualOffice.excludedExternalResources")
                  }}</span>
                  <span v-if="totalExcluded" class="subtitle1"> ({{ totalExcluded }}) </span>
                </div>
                <div class="d-flex align-center">
                  <filter-wrapper
                    class="mr-2"
                    :show-clear-button="showExcludedFiltersButton"
                    @clear-filters-clicked="onClearExcludedFilters()"
                  >
                    <v-text-field
                      v-model.trim="localExcludedFilters.search"
                      :class="['mr-3', { 'filter-active': localExcludedFilters.search?.length }]"
                      density="compact"
                      :placeholder="$t('general.search')"
                      class="search-field"
                      prepend-inner-icon="icon-search"
                      clearable
                      clear-icon="icon-x"
                      hide-details
                      @click.stop
                    />
                  </filter-wrapper>
                  <v-menu>
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="add-btn"
                        color="primary"
                        rounded
                        size="large"
                        @click="
                          onActionClick({
                            item: { excluded: true },
                            action: VirtualOfficeAction.ADD_URL_TO_URL_LIST,
                          })
                        "
                      >
                        <v-icon icon="$add" color="white" />
                        <span v-text="$t('general.add')" />
                      </v-btn>
                    </template>
                  </v-menu>
                </div>
              </template>
              <template #text>
                <virtual-office-tab-url-table
                  v-if="totalExcluded"
                  :pagination="excludedPagination"
                  :total-items="totalExcluded"
                  :items="excludedUrlList"
                  @remove-url="onActionClick($event)"
                  @page-change="onPageChange(true, $event)"
                />
              </template>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </div>
  </div>

  <v-card class="pa-4">
    <v-card-text>
      <div class="ml-4 mt-3 mr-4">
        <div v-if="mobileBannerConfiguration.visible" class="info-block mb-6 pa-4">
          <div class="d-flex justify-space-between align-start">
            <div class="d-flex align-start flex-grow-1">
              <v-icon icon="$warning" size="24" />
              <div class="d-flex flex-column flex-grow-1 ml-2">
                <div class="subtitle1" v-text="$t('network.virtualOffice.mobileBanner.title')" />
                <div
                  class="w-60 body2"
                  v-html="$t('network.virtualOffice.mobileBanner.description')"
                ></div>
                <router-link
                  class="body2 font-weight-bold text-decoration-underline coronet-primary--text"
                  :to="{
                    name: RouteName.USERS_SETTINGS_USERS_TAB,
                    query: { openImportModal: 'true' },
                  }"
                >
                  {{ $t("network.virtualOffice.mobileBanner.importList") }}
                </router-link>
              </div>
            </div>
            <v-icon size="24" role="button" @click="dismissBanner()" icon="$x" />
          </div>
        </div>
        <v-checkbox
          v-model="localValue.manualDisconnectionEnabled"
          :ripple="false"
          class="mt-1"
          :disabled="actionNotAllowed"
        >
          <template #label>
            <div
              class="subtitle1"
              :class="{ 'text-indigo-medium': actionNotAllowed }"
              v-text="$t('network.virtualOffice.manualDisconnectionEnabled')"
            />
          </template>
        </v-checkbox>
        <div
          class="body2 text--semitransparent ml-7"
          v-text="$t('network.virtualOffice.manualDisconnectionDescription')"
        />

        <div
          class="body1 mt-4 ml-7"
          :class="{
            'text-indigo-medium':
              actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled),
          }"
          v-text="$t('devicesSettings.settingsCard.applyToDevices')"
        />
        <v-autocomplete
          v-model="localValue.manualDisconnectionLabels"
          class="mt-4 ml-7"
          :items="labels"
          :label="autocompleteLabel(localValue.manualDisconnectionLabels?.length)"
          :placeholder="$t('network.virtualOffice.labelsAutocompletePlaceholder')"
          :disabled="
            actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled)
          "
          outlined
          variant="outlined"
          multiple
          return-object
          chips
          closable-chips
          clear-on-select
          item-value="id"
          item-title="name"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :closable="true"
              variant="flat"
              density="comfortable"
              size="default"
              rounded="sm"
              :color="item.raw.color"
              close-icon="$close"
              :text="item.raw.name"
              class="label-chip"
              :disabled="
                actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled)
              "
              :style="{ 'background-color': item.raw.color }"
            >
              <div class="d-flex align-center">
                <span class="ml-1 mr-2 body2" v-text="item.raw.name" />
              </div>
            </v-chip>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" title="">
              <template #default>
                <div class="d-flex">
                  <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                  <span v-text="item.raw.name" />
                </div>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-divider class="mt-8 mb-7" />
        <div class="subtitle1 mb-4" v-text="$t('network.virtualOffice.encryptionStrength.title')" />

        <v-progress-linear
          v-if="encryptionLoading"
          indeterminate
          height="4px"
          class="progress-bar mb-1"
        />
        <v-radio-group
          v-model="localValue.dataCiphers"
          :disabled="encryptionLoading || actionNotAllowed"
        >
          <template v-for="encryption in EncryptionStrength" :key="encryption">
            <v-radio
              :value="encryption"
              class="mb-1"
              :label="$t(`network.virtualOffice.encryptionStrength.${encryption}`)"
              @click.native.prevent.stop.capture="onEncryptionStrengthChange(encryption)"
            >
              <template #label>
                <div
                  class="subtitle1"
                  :class="{
                    'text-indigo-medium': encryptionLoading,
                  }"
                  v-text="$t(`network.virtualOffice.encryptionStrength.${encryption}`)"
                ></div>
              </template>
            </v-radio>
            <div
              class="body2 text--semitransparent ml-8"
              v-text="$t(`network.virtualOffice.encryptionStrength.${encryption}Hint`)"
            />
          </template>
        </v-radio-group>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted, watch, ref, type Ref, type PropType } from "vue";

import {
  useVirtualOfficeStore,
  type VirtualOfficeSettings,
} from "@/_store/network/virtual-office.module";

import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";

import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import { type DeviceLabel, useDevicesSettingsStore } from "@/_store/devices-settings.module.ts";
import { ModalWidth } from "@/constants/modals";
import { EncryptionStrength, VirtualOfficeAction } from "@/constants/network";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters.ts";

import AddVpnUrlModal from "@/components/modals/network/AddVpnUrlModal.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { FilterContext } from "@/_store/filters.module.ts";
import VirtualOfficeTabUrlTable from "@/components/tables/VirtualOfficeTabUrlTable.vue";
import { storeToRefs } from "pinia";
import SelectPredefinedVPNLabel from "@/components/modals/network/SelectPredefinedVPNLabel.vue";
import { RouteName } from "@/constants/routes.ts";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  components: { VirtualOfficeTabUrlTable, FilterWrapper },
  props: {
    settings: {
      type: Object as PropType<VirtualOfficeSettings>,
      required: true,
    },
  },
  emits: ["settings-update", "show-all-ips"],

  setup(props, { emit }) {
    const includedFilterContext = FilterContext.VIRTUAL_OFFICE_INCLUDED;
    const excludedFilterContext = FilterContext.VIRTUAL_OFFICE_EXCLUDED;
    const virtualOfficeStore = useVirtualOfficeStore();
    const deviceSettingsStore = useDevicesSettingsStore();

    const expandedIncludePanel = ref(0);
    const expandedExcludePanel: Ref<number | null> = ref(0);

    const {
      removeFromVpnUrlList,
      getMobileBannerConfiguration,
      updateVpnSettings,
      getVpnUrlList,
      addUrlToUrList,
      updateMobileBannerConfiguration,
    } = virtualOfficeStore;
    const {
      includedUrlList,
      excludedUrlList,
      urlListLoading,
      includedPagination,
      excludedPagination,
      totalIncluded,
      totalExcluded,
      encryptionLoading,
      mobileBannerConfiguration,
    } = storeToRefs(virtualOfficeStore);
    const {
      clearFilters: clearIncludedFilters,
      showClearFiltersButton: showIncludedFiltersButton,
      localFilters: localIncludedFilters,
      filtersUpdating: includedFiltersUpdating,
    } = useFilters(includedFilterContext);
    const {
      clearFilters: clearExcludedFilters,
      showClearFiltersButton: showExcludedFiltersButton,
      localFilters: localExcludedFilters,
      filtersUpdating: excludedFiltersUpdating,
    } = useFilters(excludedFilterContext);
    const { getLabels } = deviceSettingsStore;
    const { labels } = storeToRefs(deviceSettingsStore);

    const dialogStore = useDialogsStore();
    const { openDialog } = dialogStore;

    const onActionClick = ({
      item,
      action,
    }: {
      item?: { excluded: boolean; urlName?: string };
      action: string;
    }) => {
      let modalConfig: DialogConfig = {} as DialogConfig;
      switch (action) {
        case VirtualOfficeAction.REMOVE_FROM_INCLUDELIST:
        case VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              item,
              action,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.NETWORK
              ),
              callback: () => {
                removeFromVpnUrlList({
                  urlName: item!.urlName!,
                  excluded: action === VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST,
                });
              },
            }),
          };
          break;

        case VirtualOfficeAction.TOGGLE_FULL_TUNNELING:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              item,
              action: localValue.value?.fullTunneling
                ? VirtualOfficeAction.DISABLE_FULL_TUNNELING
                : VirtualOfficeAction.ENABLE_FULL_TUNNELING,
              callback: onFullTunnelingToggle,
            }),
            header: {
              title: t(
                `modals.${
                  localValue.value?.fullTunneling
                    ? VirtualOfficeAction.DISABLE_FULL_TUNNELING
                    : VirtualOfficeAction.ENABLE_FULL_TUNNELING
                }.title`
              ),
              titleClass: "text-red-dark",
              titleIcon: "warning",
            },
          };
          break;
        case VirtualOfficeAction.ADD_URL_TO_URL_LIST:
          modalConfig = {
            ...componentDialogsConfigConstructor({
              width: ModalWidth.LARGE,
              item,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.NETWORK
              ),
              component: AddVpnUrlModal,
              callback: (payload) => {
                addUrlToUrList(payload);
              },
              action,
            }),
            header: {
              title: t(
                `modals.${VirtualOfficeAction.ADD_URL_TO_URL_LIST}.${
                  item!.excluded ? "excludedListTitle" : "includeListTitle"
                }`
              ),
            },
          };
          break;
        default:
          return;
      }
      openDialog(modalConfig);
    };

    const onFullTunnelingToggle = () => {
      localValue.value.fullTunneling = !localValue.value.fullTunneling;
      if (!localValue.value.fullTunneling) {
        expandedExcludePanel.value = null;
      } else {
        expandedExcludePanel.value = 0;
      }
    };

    const onPageChange = async (
      excluded: boolean,
      pageObj: { page: number; itemsPerPage: number }
    ): Promise<void> => {
      const urlType = excluded ? "excluded" : "included";
      virtualOfficeStore.$patch({
        [`${urlType}Pagination`]: {
          page: pageObj.page - 1,
          pageSize: pageObj.itemsPerPage,
        },
      });
      await getVpnUrlList({ excluded });
    };

    const clearFiltersCallback = async (excluded: boolean): Promise<void> => {
      const urlType = excluded ? "excluded" : "included";
      virtualOfficeStore.$patch({
        [`${urlType}Pagination`]: {
          page: 0,
          pageSize: 25,
        },
      });
      await getVpnUrlList({ excluded });
    };

    const onLastItemReached = async (excluded: boolean): Promise<void> => {
      const urlType = excluded ? "excluded" : "included";
      virtualOfficeStore.$patch({
        [`${urlType}Pagination`]: { page: includedPagination.value.page, pageSize: 25 },
      });
      await getVpnUrlList({ excluded });
    };

    const { t } = useI18n();

    onMounted(async () => {
      if (!localValue.value.fullTunneling) {
        expandedExcludePanel.value = null;
      }
      await clearExcludedFilters();
      await clearIncludedFilters();
      await fetchData();
    });

    const fetchData = async () => {
      await Promise.all([
        getVpnUrlList({ excluded: false }),
        getVpnUrlList({ excluded: true }),
        getLabels(),
        getMobileBannerConfiguration(),
      ]);
    };

    const localValue: Ref<VirtualOfficeSettings> = ref({
      fullTunneling: false,
      servers: [],
      dataCiphers: EncryptionStrength.AES128,
      manualDisconnectionEnabled: false,
      manualDisconnectionLabels: [],
      blockLocalNetworkEnabled: false,
      blockLocalNetworkLabels: [],
      mode3Labels: [],
      mode2Labels: [],
      region: "",
    });

    const ips = computed(() => localValue.value?.servers?.slice(0, 5).join(", ") || "");

    const hasManyServers = computed((): boolean => localValue?.value?.servers?.length > 4);

    const actionNotAllowed = computed((): boolean => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.NETWORK
      );
    });

    const onClearIncludedFilters = async () => {
      await clearIncludedFilters();
      await getVpnUrlList({ excluded: false });
    };
    const onClearExcludedFilters = async () => {
      await clearExcludedFilters();
      await getVpnUrlList({ excluded: true });
    };

    const autocompleteLabel = (labelsLength: number) => {
      return labelsLength ? t("network.virtualOffice.labelsAutocompleteLabel") : "";
    };

    const onLabelListItemClick = (
      item: {
        raw: DeviceLabel;
      },
      event: Event,
      modelKey: "mode2Labels" | "mode3Labels",
      selected: boolean
    ) => {
      const showConfirmationModal = item.raw.predefined && !selected;
      if (showConfirmationModal) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const config = {
          data: {
            action: VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL,
            item,
            callback: () => {
              localValue.value[modelKey] = [...localValue.value[modelKey], item.raw];
            },
          },
        };
        openModal(config);
      }
    };

    const onShowAllIpsClick = () => {
      emit("show-all-ips");
    };
    const onEncryptionStrengthChange = (dataCiphers: EncryptionStrength) => {
      const config = {
        data: {
          item: {},
          action: VirtualOfficeAction.TOGGLE_ENCRYPTION_STRENGTH,
          callback: () => {
            localValue.value = { ...localValue.value, dataCiphers };
          },
        },
      };
      openModal(config);
    };

    const dismissBanner = () => {
      updateMobileBannerConfiguration({ visible: false });
    };

    const isParentSettingDisabled = (parentSettingEnabled: boolean): boolean => {
      return !parentSettingEnabled;
    };

    const openModal = (config: Partial<DialogConfig>) => {
      let modalConfig: DialogConfig | undefined;
      const { action, callback, item } = config.data ?? {};

      if (action && callback && item) {
        switch (action) {
          case VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL:
            modalConfig = {
              ...confirmationDialogsConfigConstructor({
                callback,
                action,
                width: ModalWidth.SMALL,
                text: t(`modals.${action}.description`),
                item: { name: item?.raw.name },
              }),
              header: {
                title: t(`modals.${action}.title`),
                titleClass: "text-red-dark",
                titleIcon: "warning",
              },
            };
            modalConfig.content.component = SelectPredefinedVPNLabel;
            break;
          case VirtualOfficeAction.TOGGLE_ENCRYPTION_STRENGTH:
            modalConfig = {
              ...confirmationDialogsConfigConstructor({
                item: {},
                callback,
                disable: actionNotAllowed.value,
                width: ModalWidth.SMALL,
                action,
              }),
              header: {
                title: t(`modals.${action}.title`),
                titleClass: "text-red-dark",
                titleIcon: "warning",
              },
            };
            break;
          default:
            modalConfig = undefined;
        }
      }
      if (modalConfig) {
        openDialog(modalConfig);
      }
    };

    watch(
      excludedFiltersUpdating,
      async (val) => {
        if (val) await getVpnUrlList({ excluded: true });
      },
      { deep: true }
    );
    watch(
      includedFiltersUpdating,
      async (val) => {
        if (val) await getVpnUrlList({ excluded: false });
      },
      { deep: true }
    );

    watch(
      props.settings,
      (newValue) => {
        localValue.value = newValue as VirtualOfficeSettings;
      },
      { deep: true, immediate: true }
    );

    watch(
      localValue,
      async () => {
        try {
          await updateVpnSettings(localValue.value);
        } catch (error) {
          localValue.value = cloneDeep(props.settings);
          console.error(error);
        }
      },
      { deep: true }
    );

    return {
      RouteName,
      onFullTunnelingToggle,
      onPageChange,
      onLastItemReached,
      clearFiltersCallback,
      VirtualOfficeAction,
      onActionClick,
      getVpnUrlList,
      updateVpnSettings,
      onClearExcludedFilters,
      onClearIncludedFilters,
      showExcludedFiltersButton,
      clearExcludedFilters,
      localExcludedFilters,
      excludedFilterContext,
      excludedFiltersUpdating,
      includedFiltersUpdating,
      expandedExcludePanel,
      expandedIncludePanel,
      includedUrlList,
      excludedUrlList,
      urlListLoading,
      includedPagination,
      excludedPagination,
      totalIncluded,
      totalExcluded,
      clearIncludedFilters,
      showIncludedFiltersButton,
      localIncludedFilters,
      localValue,
      ips,
      hasManyServers,
      actionNotAllowed,
      labels,
      autocompleteLabel,
      openDialog,
      onLabelListItemClick,
      mobileBannerConfiguration,
      dismissBanner,
      encryptionLoading,
      isParentSettingDisabled,
      EncryptionStrength,
      onEncryptionStrengthChange,
      onShowAllIpsClick,
    };
  },
});
</script>

<style scoped lang="scss">
.v-card-text {
  padding: 0;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
:deep(.v-expansion-panel-title__overlay) {
  pointer-events: none;
}

.v-card.full-tunneling-card {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.v-card.full-tunneling-card-action {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

:deep(.v-table) {
  box-shadow: none !important;

  &.disabled {
    span,
    div {
      color: rgb(var(--v-theme-indigo-light));
    }
  }
}
.section-icon {
  font-size: 100px;
}

.table-header-icon {
  font-size: 40px;
}

.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}

i.icon.text-primary:before {
  color: rgb(var(--v-theme-gray-faint)) !important;
}

.label-popup-block span {
  flex: unset !important;
}

:deep(*) {
  .v-input--checkbox .v-input__slot {
    margin-bottom: 0 !important;
  }

  .label-chip {
    .icon-rules-close:before {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>
