<template>
  <div class="content-md margin-auto mt-4">
    <div class="mb-3">
      <v-breadcrumbs :items="breadcrumbsItems" />
    </div>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="devices-page-title">
          {{ $t("devices.mspTitle") }}
        </span>
        <v-chip class="ml-2 chip--counter">
          {{ totalDevices }}
        </v-chip>
      </div>
    </div>

    <div class="mb-4">
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(clearFiltersCallback)"
      >
        <div class="grid-container">
          <msp-workspace-descentant-select
            class="mr-2"
            :value="localFilters.childWorkspaceIds"
            @value-updated="localFilters.childWorkspaceIds = $event"
          />
          <v-select
            v-model="localFilters.labels"
            :items="labelItems"
            bg-color="white"
            menu-icon="icon-triangle"
            item-value="id"
            item-title="name"
            :menu-props="{ maxHeight: '300' }"
            class="label-filter filter-menu mr-2"
            :class="{ 'filter-active': localFilters.labels?.length }"
            :placeholder="$t('devices.filters.groups')"
            :no-data-text="$t('devices.filters.label.noData')"
            data-testid="devices-page-label-filter"
            density="compact"
            variant="outlined"
            multiple
            rounded
            hide-details
            background-color="white"
            return-object
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{ $t("devices.filters.groups") }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.labels?.length" class="filter-label">
                {{ localFilters.labels.length }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.name" />
              <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
              <v-list-item v-else v-bind="props" title="">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <v-list-item-title
                  :data-testid="`devices-page-label-filter-${item}-item`"
                  class="d-flex align-center label-popup-block"
                  title=""
                >
                  <span
                    class="color-preview mr-2"
                    :style="{ 'background-color': item.raw.color }"
                  />
                  <span>{{ item.raw.name }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.osVersion"
            :items="osVersions"
            :menu-props="{ maxHeight: '300' }"
            class="os-version-filter filter-menu mr-2 fit"
            :class="{ 'filter-active': localFilters.osVersion }"
            :placeholder="$t('devices.filters.osVersion')"
            data-testid="devices-page-os-version-filter"
            density="compact"
            item-value="name"
            item-title="name"
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ item }">
              <span class="body2">
                {{ item.title }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
              <v-list-item
                v-else
                :data-testid="`devices-page-os-version-filter-${item}-item`"
                v-bind="props"
              >
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.clientVersion"
            :items="clientVersions"
            :menu-props="{ maxHeight: '300' }"
            class="agent-version-filter filter-menu mr-2 fit"
            :class="{ 'filter-active': localFilters.clientVersion }"
            :placeholder="$t('devices.filters.clientVersion')"
            data-testid="devices-page-client-version-filter"
            density="compact"
            item-value="name"
            item-title="name"
            outlined
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ item }">
              <span class="body2">
                {{ `Coro ${item.title}` }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
              <v-list-item
                v-else
                v-bind="props"
                :data-testid="`devices-page-client-version-filter-${item}-item`"
              >
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.vulnerabilities"
            :items="Object.values(DeviceVulnerability)"
            :menu-props="{ maxHeight: '300' }"
            class="vulnerabilities-filter filter-menu mr-2"
            :class="{
              'filter-active': localFilters.vulnerabilities && localFilters.vulnerabilities.length,
            }"
            :placeholder="$t('devices.filters.vulnerability')"
            data-testid="devices-page-vulnerabilities-filter"
            density="compact"
            outlined
            multiple
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("devices.filters.vulnerability")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.vulnerabilities?.length" class="filter-label">{{
                localFilters.vulnerabilities.length
              }}</span>
            </template>
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :title="$t(`tickets.eventTriggers.${camelCase(item.raw)}`)"
              >
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-combobox
            v-model="localFilters.search"
            variant="outlined"
            clearable
            clear-icon="icon-x"
            background-color="white"
            :items="suggestions"
            :placeholder="$t('general.search')"
            class="search-field"
            hide-details
            density="compact"
            rounded
            bg-color="white"
            menu-icon=""
            persistent-clear
            data-testid="tickets-page-search-field"
            :class="{
              'filter-active': localFilters.search,
            }"
          >
          </v-combobox>
        </div>
      </filter-wrapper>
    </div>
    <list-with-preview :show-skeleton-loader="showSkeletonLoader">
      <template #list>
        <list-table
          :items="devices"
          item-key="id"
          :pagination="pagination"
          :loading="loading"
          :selected-items="selectedItems"
          :is-indeterminate="isIndeterminate"
          :all-items-selected="allItemsSelected"
          :selected-items-quantity="selectedItemsQuantity"
          :total-items="totalDevices"
          :available-bulk-actions="availableBulkActions"
          :actions-loading="actionsLoading"
          @page-changed="onPageChange"
          @update-preview="onPreviewUpdate($event)"
          @bulk-menu-clicked="getBulkActions"
          @bulk-action-clicked="onBulkActionClick($event)"
          @handle-one-row-selection="handleOneRowSelection($event.selected, $event.item)"
          @handle-all-rows-selection="handleAllRowsSelection"
        >
          <template #list-item="{ item, index }">
            <div class="list-item-grid py-1">
              <div class="list-item-grid__icon-block">
                <v-icon :icon="getDeviceIcon(item.osType)" size="40" class="mr-4" />
              </div>
              <div class="list-item-grid__details-block d-flex flex-column justify-center pr-2">
                <div :data-testid="`users-table-name-row-${index}`" class="subtitle2">
                  {{ item.lastLoggedInUsers.length ? item.lastLoggedInUsers[0] : item.email }}
                </div>
                <div :data-testid="`users-table-email-row-${index}`" class="body2">
                  <span :data-testid="`devices-table-hostname-col-row-${index}`">
                    {{ item.hostName }}
                  </span>
                  <span
                    v-if="item.offline"
                    class="offline-label"
                    :data-testid="`devices-table-is-offline-row-${index}`"
                    >{{ ` (${$t("general.offline")})` }}
                  </span>
                  <div :data-testid="`devices-table-workspace-col-row-${index}`">
                    {{ item.workspaceName }}
                  </div>
                </div>
                <div
                  class="body3 white-space-normal text-indigo-medium"
                  :class="{
                    'reduced-max-width':
                      showIsolationStatus(item.isolationStatus) || item.labels.length,
                  }"
                  :data-testid="`devices-table-os-col-row-${index}`"
                >
                  {{ item.osDisplayName }}
                </div>
              </div>
              <div class="list-item-grid__status-block">
                <v-tooltip
                  v-if="item.hasOpenTickets || item.outdated"
                  open-delay="300"
                  location="top"
                >
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      icon="$warning"
                      v-bind="props"
                    />
                  </template>
                  {{
                    item.hasOpenTickets
                      ? $t("general.unresolvedTickets")
                      : $t("devices.clientOutdated")
                  }}
                </v-tooltip>
                <v-tooltip v-else open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      v-bind="props"
                      icon="$circleCheck"
                    />
                  </template>
                  {{ $t("general.noTickets") }}
                </v-tooltip>
              </div>
              <div class="list-item-grid__bottom-block">
                <div v-if="item.labels.length" class="d-flex align-center labels-preview">
                  <div v-for="label in getFilteredLabels(item.labels)" :key="label.id">
                    <v-tooltip open-delay="300" location="top">
                      <template #activator="{ props }">
                        <div
                          class="color-preview mr-1"
                          :style="{ 'background-color': label.color }"
                          v-bind="props"
                        />
                      </template>
                      {{ label.name }}
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </list-table>
      </template>
      <template #preview>
        <msp-device-preview :device="preview" :is-msp="true"></msp-device-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { type DeviceLabel } from "@/_store/devices-settings.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import MspDevicePreview from "@/components/msp/MspDevicePreview.vue";
import { camelCase } from "lodash";
import ListWithPreview from "@/components/ListWithPreview.vue";
import type { Pagination } from "@/types";
import { SearchSuggestion } from "@/constants/general";
import {
  ActionTypeTarget,
  DeviceAction,
  DeviceVulnerability,
  IsolationStatus,
  OsType,
} from "@/constants/devices";
import isEqual from "lodash/isEqual";
import { type MspDeviceListItem, useMspDevicesStore } from "@/_store/msp/msp-devices.module.ts";
import ListTable from "@/components/tables/ListTable.vue";
import { useSimpleSelectableTable } from "@/composables/useSimpleSelectableTable.ts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils.ts";
import { RolePermissionsScope } from "@/_store/roles.module.ts";
import { SubscriptionModule } from "@/constants/workplaces.ts";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import { ModalWidth } from "@/constants/modals.ts";
import DlpScanModal from "@/components/modals/DlpScanModal.vue";
import AddOrRemoveDeviceLabelModal from "@/components/modals/AddOrRemoveDeviceLabelModal.vue";
import { useDialogsStore } from "@/_store/dialogs.module.ts";
import MspWorkspaceDescentantSelect from "@/components/msp/MspWorkspaceDescentantSelect.vue";
const filterContext = FilterContext.MSP_DEVICES;
const suggestions = [SearchSuggestion.TICKET_ID];

export default defineComponent({
  components: {
    MspWorkspaceDescentantSelect,
    ListTable,
    ListWithPreview,
    MspDevicePreview,
    FilterWrapper,
  },
  props: {
    hasVulnerabilities: {
      type: String,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
    widget: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const devicesStore = useMspDevicesStore();
    const filtersStore = useFiltersStore();
    const dialogStore = useDialogsStore();
    const i18n = useI18n();
    const preview = ref<MspDeviceListItem | null>(null);
    const osVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const clientVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const availableBulkActions = ref<{ name: string; disabled: boolean; displayName: string }[]>(
      []
    );
    const actionsLoading = ref(false);

    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } =
      useFilters(filterContext);

    const {
      loading,
      devices,
      totalDevices,
      pagination,
      deviceDetailsLoading,
      showSkeletonLoader,
      osVersions: osVersionsFromStore,
      clientVersions: clientVersionsFromStore,
    } = storeToRefs(devicesStore);

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      clearSelection,
    } = useSimpleSelectableTable(devices);

    const labelItems = computed(() => {
      const labels = devicesStore.availableLabels ?? [];
      const statuses = devicesStore.statuses ?? [];
      const result = [];
      if (statuses.length) {
        result.push({ subheader: true, name: i18n.t("devices.filters.status") }, ...statuses, {
          divider: true,
        });
      }
      result.push({ subheader: true, name: i18n.t("devices.filters.labels") }, ...labels);
      return result;
    });

    const onPreviewUpdate = (item: MspDeviceListItem | null) => {
      preview.value = item ? { ...item } : null;
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          devicesStore.resetPagination();
          await devicesStore.getDevices();
        }
      },
      { deep: true, immediate: true }
    );

    const getDeviceIcon = (osType?: OsType) => {
      if (osType) {
        const iconMap = {
          [OsType.WINDOWS]: `$windowsPc`,
          [OsType.OSX]: `$macPc`,
          [OsType.IOS]: `$iosDevice`,
          [OsType.ANDROID]: `$androidDevice`,
          [OsType.MAC_OS]: `$macPc`,
          [OsType.LINUX]: `$linuxPc`,
        };
        return iconMap[osType];
      }
      return undefined;
    };

    /**
     * Filters the labels that are not predefined and returns only first 5 of them.
     * @param labels
     */
    const getFilteredLabels = (labels: DeviceLabel[]): DeviceLabel[] =>
      labels.filter((label) => !label.predefined).slice(0, 5);

    const onBulkActionClick = async (action: string) => {
      const item = {
        action,
        enrollmentCodes: selectedItems.value.map((item) => item.enrollmentCode),
      };

      const disable = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.VIEWS,
        "devicesView"
      );
      switch (action) {
        case DeviceAction.EXAMINE_MALWARE:
          // TODO: implement actual action after implementation of msp tickets
          console.log(action);
          break;
        case DeviceAction.MARK_AS_PROCESSED:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                ...item,
                callback: (payload: { action: DeviceAction; enrollmentCodes: string[] }) => {
                  devicesStore.applyDeviceAction({
                    action,
                    item: { ...payload },
                  });
                },
                modules: [
                  SubscriptionModule.ENDPOINT_SECURITY,
                  SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
                  SubscriptionModule.EDR,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable,
            })
          );
          break;
        case DeviceAction.DLP_PARTIAL_SCAN:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              action,
              item: {
                ...item,
                partialScan: true,
              },
              component: DlpScanModal,
              callback: devicesStore.applyDeviceAction,
              disable,
            })
          );
          break;
        case DeviceAction.ADD_LABEL:
        case DeviceAction.REMOVE_LABEL:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item,
              action,
              component: AddOrRemoveDeviceLabelModal,
              width: ModalWidth.SMALL,
              callback: devicesStore.applyDeviceAction,
              disable,
            })
          );
          break;
        case DeviceAction.EXPORT_TO_CSV:
          await devicesStore.exportDevicesToCsv({ item });
          break;
        default:
          dialogStore.openDialog({
            ...confirmationDialogsConfigConstructor({
              action,
              disable,
              item,
              callback: devicesStore.applyDeviceAction,
            }),
            content: {
              html: i18n.t(
                `modals.${action}.description`,
                { quantity: selectedItemsQuantity.value },
                selectedItemsQuantity.value
              ),
            },
          });
          break;
      }
    };

    const showIsolationStatus = (status: IsolationStatus): boolean =>
      [IsolationStatus.ISOLATED].includes(status);

    const getBulkActions = async () => {
      availableBulkActions.value = [];
      actionsLoading.value = true;
      try {
        const payload = {
          enrollmentCodes: selectedItems.value.map((item) => item.enrollmentCode),
          target: ActionTypeTarget.BULK,
        };
        const { data } = await devicesStore.getActions(payload);
        availableBulkActions.value = data.items.map((item: DeviceAction) => {
          return {
            name: item,
            // todo: for now always enabled, because there's no way to understand if dlp scan enabled, should
            // be returned from BE
            disabled: false,
            displayName: i18n.t(`devices.actions.${item}`),
          };
        });
        actionsLoading.value = false;
      } catch {
        actionsLoading.value = false;
      }
    };

    onMounted(async () => {
      const filtersWithQuery = {
        ...{ ...localFilters.value },
        hasVulnerabilities: props.hasVulnerabilities ? props.hasVulnerabilities === "true" : null,
        search: props.search ? props.search : localFilters.value.search,
        widget: props.widget ?? null,
      };
      await devicesStore.init();
      populateVersionsList();
      // If filters are not equal, then update filters which triggers the watcher `filtersUpdating`
      if (!isEqual(filtersWithQuery, localFilters.value)) {
        localFilters.value = filtersWithQuery;
      } else {
        // else - do request manually
        await devicesStore.getDevices(true);
      }
    });

    const onPageChange = async (pagination: Pagination) => {
      devicesStore.setPagination(pagination);
      await devicesStore.getDevices();
    };

    function populateVersionsList() {
      const osTypesForVersions = [OsType.WINDOWS, OsType.OSX, OsType.IOS, OsType.ANDROID] as const;
      osVersions.value = [];
      clientVersions.value = [];
      osTypesForVersions.forEach((neededVersion) => {
        const lowercaseVersion = neededVersion.toLowerCase() as Lowercase<
          Exclude<OsType, OsType.MAC_OS>
        >;
        if (osVersionsFromStore.value[lowercaseVersion]?.length) {
          osVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...(osVersionsFromStore.value[lowercaseVersion] ?? []).map((v) => ({ name: v }))
          );
        }

        if (clientVersionsFromStore.value?.[lowercaseVersion]?.length) {
          clientVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...(clientVersionsFromStore.value[lowercaseVersion] ?? [])
              .slice()
              .sort()
              .reverse()
              .map((v) => ({ name: v }))
          );
        }
      });
    }

    const clearFiltersCallback = async () => {
      await devicesStore.getDevices();
      populateVersionsList();
    };

    onUnmounted(() => {
      filtersStore.resetFilters(FilterContext.MSP_DEVICES);
    });

    return {
      DeviceVulnerability,
      breadcrumbsItems: [
        {
          title: `‹ ${i18n.t("general.home")}`,
          disabled: false,
          to: { path: "/portal/dashboard" },
        },
      ],
      coronetSkeletonLoaderTypes,
      preview,
      loading,
      devices,
      totalDevices,
      pagination,
      deviceDetailsLoading,
      localFilters,
      labelItems,
      showSkeletonLoader,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      suggestions,
      osVersions,
      clientVersions,
      onPreviewUpdate,
      onPageChange,
      clearFiltersCallback,
      camelCase,
      getBulkActions,
      onBulkActionClick,
      getDeviceIcon,
      getFilteredLabels,
      showIsolationStatus,
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      clearSelection,
      availableBulkActions,
      actionsLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-chip-group .v-chip {
    min-width: 32px;
  }

  .clear-btn {
    z-index: 0 !important;
  }

  .label-popup-block span {
    flex: unset !important;
  }
}

.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr minmax(240px, 1fr) 1fr 1fr 1fr;
  align-items: center;
}
</style>
